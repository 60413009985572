.str-chat {
  --str-chat__own-message-bubble-color: var(--str-chat__owntext);
  --str-chat__message-bubble-border-radius: var(
    --bn-chat__message-bubble-border-radius
  );
  --str-chat-message-options-size: 1.5rem;
  --str-chat__message-options-button-size: 1.5rem;

  .str-chat__main-panel-inner {
    .str-chat__message.str-chat__message--me {
      --str-chat__quoted-message-bubble-background-color: var(
        --str-chat__secondary-surface-color
      );

      .str-chat__message--deleted-inner {
        @apply rounded-ee-none;
        --str-chat__deleted-message-background-color: var(
          --str-chat__own-message-bubble-background-color
        );
        --str-chat__deleted-message-color: var(
          --str-chat__own-message-bubble-color
        );
      }
    }

    .str-chat__message.str-chat__message--other {
      --str-chat__quoted-message-bubble-background-color: var(
        --str-chat__own-message-bubble-background-color
      );

      .str-chat__message--deleted-inner {
        @apply rounded-es-none;
        --str-chat__deleted-message-background-color: var(
          --str-chat__message-bubble-background-color
        );
        --str-chat__deleted-message-color: var(
          --str-chat__message-bubble-color
        );
      }

      .str-chat__quoted-message-preview {
        --str-chat__message-bubble-color: var(
          --str-chat__own-message-bubble-color
        );
      }
    }
  }
}

.str-chat__main-panel-inner {
  &.isGroup {
    .str-chat__message {
      &.str-chat__message--other:not(.str-chat__message--system) {
        @apply gap-x-1;
        grid-template-areas: 'avatar message' '. replies' '. translation-notice' '. custom-metadata' '. metadata';
        grid-template-columns: auto 1fr;
      }

      .str-chat__message-sender-avatar {
        @apply block;
      }
    }

    .str-chat__quoted-message-preview {
      .str-chat__message-sender-avatar {
        @apply hidden;
      }
    }
  }

  .str-chat__message,
  .str-chat__quoted-message-preview {
    &.str-chat__message--other,
    &.str-chat__quoted-message-preview {
      grid-template-areas: 'message' 'replies' 'translation-notice' 'custom-metadata' 'metadata';
      grid-template-columns: 1fr;
    }

    &.str-chat__message--me .str-chat__message-sender-avatar,
    .str-chat__message-sender-avatar {
      @apply hidden;
    }

    .str-chat__message-inner {
      @apply gap-x-1;

      .str-chat__message-bubble {
        @apply text-sm md:text-base;

        .str-chat__message-text {
          @apply p-4;
        }
      }

      .str-chat__message-options {
        .mat-icon {
          @apply font-bold;
        }
      }
    }

    .str-chat__message-metadata {
      @apply gap-x-0 min-h-6 items-center;
      margin-block-start: 0;

      .str-chat__message-simple-timestamp {
        @apply -mb-1;
      }

      .str-chat__message-sender-name,
      .str-chat__message-edited-timestamp {
        @apply hidden;
      }

      .str-chat__mesage-simple-edited {
        @apply ml-1;
      }
    }

    .str-chat__quoted-message-bubble {
      @apply p-2;
    }
  }

  .str-chat__message {
    .str-chat__quoted-message-preview {
      margin-block-end: 0.5rem;
    }

    .str-chat__message-metadata {
      @apply font-medium;
    }

    .str-chat__message--deleted-inner {
      @apply p-4 text-sm md:text-base italic;
      --str-chat__deleted-message-border-radius: var(
        --str-chat__message-bubble-border-radius
      );
    }

    .str-chat__message-mention {
      @apply font-bold;
    }

    &--highlighted {
      @apply bg-transparent;
    }
  }

  .str-chat__quoted-message-preview {
    .str-chat__quoted-message-bubble {
      @apply gap-y-2 text-xs rounded md:rounded-lg rounded-es-none md:rounded-es-none;
    }
  }

  .str-chat__unread-messages-separator-wrapper {
    @apply hidden;
  }
}

.str-chat__li--bottom,
.str-chat__li--single {
  .str-chat__message {
    margin-block-end: 0;
  }
}

.str-chat__li--top,
.str-chat__li--single {
  .str-chat__message--me {
    .str-chat__message-bubble {
      @apply rounded-ee-none;
    }
  }
}

.str-chat__li--middle,
.str-chat__li--bottom {
  .str-chat__message--me {
    .str-chat__message-bubble {
      @apply rounded-ee-none rounded-se-lg md:rounded-se-2xl;
    }
  }

  .str-chat__message--other {
    .str-chat__message-bubble {
      @apply rounded-es-none rounded-ss-lg md:rounded-ss-2xl;
    }
  }
}

.str-chat__message
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  .str-chat__message-text-inner,
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  .str-chat__message-text-inner {
  @apply overflow-x-hidden;
}
