@import '../colors';

.mdc-list-item.active {
  .mdc-list-item__primary-text {
    font-weight: bold !important;
  }
}

.mdc-list-item {
  &:hover::before,
  &:focus::before {
    background-color: darken(whitesmoke, 50%) !important;
    opacity: 0.1 !important;
  }
  &.active {
    .material-symbols-rounded {
      font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;
    }
    &::before {
      background-color: $primary-color !important;
      opacity: 0.1 !important;
    }
  }

  &:not(mat-option) {
    border-radius: 36px !important;
  }
}

.highlighted.mdc-list-item.active::before {
  background-color: $secondary-list-item !important;
}

.mat-mdc-list-item:not(.active) {
  .mdc-list-item__content {
    .mdc-list-item__primary-text {
      color: $bn-grey-font;
    }
  }
}
