.mdc-menu-surface {
  border-radius: 10px !important;
  -webkit-box-shadow: 0 5px 20px 5px rgba(0, 8, 65, 0.15) !important;
  box-shadow: 0 5px 20px 5px rgba(0, 8, 65, 0.15) !important;
}

.bn-combined-field-divider {
  display: none;
}

// don't combine on mobile
@media screen and (min-width: 769px) {
  mat-form-field.bn-combined-field-left {
    .mat-mdc-text-field-wrapper {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  mat-form-field.bn-combined-field-right {
    .mat-mdc-text-field-wrapper {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .bn-combined-field-divider {
    display: block;
    height: 56px;
    border: 1px solid #d7dce4;
  }
}

.mat-mdc-checkbox-checked {
  .mdc-checkbox__background {
    background-color: $primary-color !important;
  }
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: $primary-color !important;
}

.mdc-checkbox__checkmark {
  color: white !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  background-color: transparent !important;
}
