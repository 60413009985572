.stream-chat__paginated-list {
  @apply overflow-y-auto gap-0;
}

stream-paginated-list {
  @apply h-full min-h-full;
}

stream-user-list {
  @apply h-full min-h-full;

  .str-chat__user-list-item {
    @apply gap-0;
  }
}
