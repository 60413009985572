.str-chat__image-carousel {
  @apply flex flex-row flex-nowrap flex-1 h-auto items-center justify-center px-8;

  .stream-chat-angular__image-modal-image {
    @apply block w-auto h-full max-h-[calc(100vh_-_128px)];
  }

  .stream-chat-angular__image-modal-image ~ div {
    .stream-chat-angular__image-modal-stepper {
      @apply absolute block h-full inset-y-0;

      &.str-chat__image-carousel-stepper-prev {
        @apply left-0;
      }

      &.str-chat__image-carousel-stepper-next {
        @apply right-0;
      }
    }
  }
}
