@import '../colors';

mat-form-field {
  .mat-mdc-text-field-wrapper {
    border-radius: 28px;
    border: 1px solid $border-color;
    background-color: $form-bg-color;

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  &:hover {
    .mat-mdc-text-field-wrapper {
      border: 1px solid $primary-color;
    }
  }

  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      border: 1px solid $primary-color;
    }
  }

  &.ng-touched.ng-invalid {
    .mat-mdc-text-field-wrapper {
      border: 1px solid $error-color;
    }
  }

  &.success {
    .mat-mdc-text-field-wrapper {
      border: 1px solid $success-dark-color;
      background-color: $success-bright-color;
      font-weight: 700;

      mat-icon,
      mat-label {
        color: $success-dark-color;
      }
    }
  }
}
