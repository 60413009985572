@import '../colors';

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: $input-color !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background-color: $box-bg-color !important;
}
