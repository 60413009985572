.quote__name {
  @apply font-medium text-bn-primary-blue;
}

.message-input-quote,
.message-input-edit {
  &__content {
    &__text {
      p {
        @apply m-0;
      }
    }
  }

  &__attachments {
    --str-chat__image-attachment-border-radius: var(
      --str-chat__border-radius-xxs
    );

    .str-chat__attachment-list {
      .str-chat__message-attachment--image {
        > img {
          @apply max-w-14 max-h-14;
        }

        &:not(.str-chat__message-attachment--card) {
          > img {
            @apply max-w-14 max-h-14;
          }
        }
      }
    }
  }
}
