.cdk-overlay-pane.mat-mdc-dialog-panel {
  &:has(.str-chat__message-reactions-details) {
    max-height: 40vh !important;
    min-height: 40vh !important;
    max-width: 90vw !important;
    min-width: 90vw !important;
    --str-chat__messsage-reactions-details--selected-color: solid #2880fe;

    @media (min-width: 768px) {
      max-width: 20vw !important;
      min-width: 20vw !important;
    }
  }

  .str-chat__message-reactions-details {
    @apply flex flex-col flex-nowrap gap-y-4;

    .str-chat__message-reactions-details-reaction-types {
      @apply flex flex-nowrap gap-x-4;
    }

    .str-chat__message-reaction-emoji-big {
      @apply min-h-8 leading-none;
    }

    .str-chat__message-reactions-details-reacting-users {
      @apply overflow-y-auto min-h-full;

      .stream-chat__paginated-list {
        @apply flex flex-col gap-y-2;

        .stream-chat__paginated-list-item {
          .str-chat__user-list-item {
            @apply flex flex-nowrap gap-x-4;

            .str-chat__avatar {
              @apply overflow-hidden;
            }

            .str-chat__avatar-fallback {
              @apply bg-bn-chat-avatar;
            }

            .str-chat__user-item--name {
              @apply truncate;
            }
          }
        }
      }
    }
  }
}
