// Rich text styles

%list-base {
  list-style-position: outside;
  padding-left: 20px;

  p {
    margin: 0;
  }
}

.NgxEditor__Content,
.NgxEditor__View {
  ul {
    @extend %list-base;
    list-style-type: disc;
  }

  ol {
    @extend %list-base;
    list-style-type: decimal;
  }

  a {
    color: $primary-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Editor styles

.NgxEditor {
  border-radius: 16px !important;
}

.NgxEditor,
.NgxEditor__Content {
  min-height: 150px;
}

.NgxEditor__MenuBar {
  padding-bottom: 16px !important;

  .NgxEditor__Popup--Form {
    .NgxEditor__Popup--Label {
      font-weight: 700;
    }

    input {
      border: 1px solid #000000;
      border-radius: 5px;
    }

    // button type submit rule
    button[type='submit'] {
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 4px;
    }
  }
}

@media screen and (max-width: 500px) {
  .NgxEditor__Popup {
    position: fixed !important;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%, -40%);
  }
}
