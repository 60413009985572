.emoji-mart {
  @apply pt-11 border-none rounded bg-bn-chat-light-blue flex flex-col shadow-xl text-bn-chat-secondary-grey relative;
  font-family: var(--str-chat__font-family), sans-serif;

  .emoji-mart-bar {
    @apply hidden;
  }

  .emoji-mart-category {
    @apply flex w-full flex-wrap pb-4 justify-between;

    &:last-of-type {
      @apply pb-0;
    }

    .emoji-mart-category-label {
      @apply pt-0 pb-3 bg-bn-chat-light-blue w-full px-1;

      > span {
        @apply p-0 bg-bn-chat-light-blue text-xs font-medium;
      }
    }
  }

  .emoji-mart-category[style*="display:block;"],
  .emoji-mart-category[style*="display: block;"] {
    display: flex !important;
  }

  .emoji-mart-emoji {
    @apply p-1 flex-1 block;
  }

  .emoji-mart-search {
    @apply mt-0 mb-4 pr-2 static flex flex-nowrap items-center h-10 rounded-[1.75rem] bg-bn-chat-avatar mx-2 before:content-['Alle_Emojis'] before:block before:absolute before:top-2 before:left-2 before:text-sm md:before:text-base before:font-bold;

    > input {
      @apply pt-1 flex-1 order-2 p-0 border-none rounded-none text-sm bg-transparent md:text-base text-bn-chat-secondary-grey placeholder-bn-chat-secondary-grey;
    }

    .emoji-mart-search-icon {
      @apply relative p-0 order-1 top-auto right-auto flex items-center justify-center size-10 overflow-hidden;

      > svg {
        @apply size-5 opacity-100 fill-bn-chat-secondary-grey;
      }
    }
  }

  .emoji-mart-scroll {
    @apply overflow-y-auto py-0 px-1 h-56 scrollbar-thin;
  }
}
