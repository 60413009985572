vg-overlay-play .vg-overlay-play {
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  display: block;
  color: white;
  width: 100%;
  height: 100%;
  font-size: 80px;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
}

.vg-icon-play_arrow:before {
  font-family: 'Material Symbols Rounded';
  content: 'play_arrow';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

vg-overlay-play .vg-icon-play_arrow:before {
  scale: 0.6;
  padding: 8px;
  border: 2px solid white;
  border-radius: 100%;
}

.vg-icon-volume_up:before {
  content: 'volume_up';
  font-family: 'Material Symbols Rounded';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.vg-icon-volume_off:before {
  content: 'volume_off';
  font-family: 'Material Symbols Rounded';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.vg-icon-volume_mute:before {
  content: 'volume_mute';
  font-family: 'Material Symbols Rounded';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.vg-icon-volume_down:before {
  content: 'volume_down';
  font-family: 'Material Symbols Rounded';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.vg-icon-fullscreen:before {
  content: 'fullscreen';
  font-family: 'Material Symbols Rounded';
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

vg-time-display {
  font-family: 'Orkney', 'Helvetica Neue', sans-serif !important;
  line-height: 52px !important;
}
