@import '../colors';

.mdc-checkbox__background {
  border-radius: 5px !important;
  border-color: $primary-color !important;
}

.checkbox-fulltime .mdc-checkbox__native-control[disabled] {
  &:checked ~ .mdc-checkbox__background {
    background-color: #001a41 !important;
  }
  & ~ .mdc-checkbox__background {
    border-color: #001a41 !important;
  }
}

.checkbox-substitution .mdc-checkbox__native-control[disabled] {
  &:checked ~ .mdc-checkbox__background {
    background-color: #3b0716 !important;
  }
  & ~ .mdc-checkbox__background {
    border-color: #3b0716 !important;
  }
}
