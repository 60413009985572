@import '../colors';

.inner-html-list {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 20px;
}

.inner-html-link {
  color: $primary-color;

  &:hover {
    text-decoration: underline;
  }
}
