.mat-mdc-menu-panel.menu {
  @apply min-w-[12.5rem] bg-bn-chat-light-blue rounded shadow-xl;

  .mat-mdc-menu-content {
    @apply py-2;

    .mat-mdc-menu-item {
      @apply flex flex-nowrap gap-x-2 pl-2 pr-3 text-sm text-bn-chat-primary-grey md:text-base;

      .mat-icon {
        @apply mr-0;
      }

      .mat-mdc-menu-item-text {
        @apply mt-1;
      }
    }
  };
}
