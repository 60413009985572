.str-chat__message-reaction-selector {
  &.str-chat-angular-v5__reaction-selector {
    @apply mx-2 my-0;

    .str-chat__message-reactions-options {
      @apply py-0 w-full justify-between;
    }
  }

  .str-chat__message-reactions-options {
    margin-block-end: 0;

    .str-chat__message-reactions-option {
      @apply size-10 rounded-full;

      .str-chat__message-reaction-emoji {
        @apply size-10 text-2xl leading-none h-auto -mb-1.5;
      }
    }
  }
}

.str-chat__reaction-selector {
  .str-chat__message-reactions-options {
    --str-chat__message-reactions-options-background-color: var(
      --str-chat__grey300
    );
    --str-chat__message-reactions-option-background-color: var(
      --str-chat__grey300
    );
    --str-chat__message-reactions-option-selected-background-color: var(
      --str-chat__own-message-bubble-background-color
    );

    .str-chat__message-reactions-option {
      &:hover {
        background-color: var(
          --str-chat__message-reactions-option-selected-background-color
        );
      }
    }
  }
}
