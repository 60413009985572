@import '../colors';

mat-chip.location {
  background-color: transparent !important;
  .mdc-evolution-chip__text-label {
    color: $hover-color !important;
    padding-top: 4px !important;
  }

  &.first {
    .mdc-evolution-chip__text-label {
      font-weight: bold !important;
    }
  }

  &.selected {
    background-color: $bn-blue-light !important;
    .mdc-evolution-chip__text-label {
      color: $bn-text-color !important;
    }
  }
}

.mat-mdc-standard-chip:not(
    .mdc-evolution-chip--disabled
  ).selected.location-chip {
  background-color: black !important;
}

.mat-mdc-standard-chip:not(
    .mdc-evolution-chip--disabled
  ).selected.mat-mdc-standard-chip
  .mdc-evolution-chip__text-label {
  color: white;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).location
  .mdc-evolution-chip__action--primary {
  cursor: pointer;
}

mat-chip {
  background-color: #cbd1db !important;
}

.mat-mdc-standard-chip.optimize .mdc-evolution-chip__text-label {
  @apply w-full truncate block leading-none p-1 pb-0;
}

.mat-mdc-standard-chip.optimize .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip.optimize .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip.optimize .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
