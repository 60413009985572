.str-chat__channel-preview {
  @apply px-4 py-2 gap-x-4 pointer-events-none;

  &:hover {
    @apply transition-colors bg-bn-chat-light-blue;
  }

  &.str-chat__channel-preview-messenger--unread {
    .str-chat__channel-preview-end-second-row {
      .str-chat__channel-preview-messenger--time {
        @apply text-bn-primary-blue;
      }
    }
  }

  .str-chat__channel-preview-loading {
    @apply hidden;
  }

  .str-chat__channel-preview-end {
    @apply gap-y-0;

    .str-chat__channel-preview-end-first-row {
      @apply gap-x-4 items-center;

      .str-chat__channel-preview-messenger--name {
        @apply text-bn-chat-primary-grey;
      }

      .str-chat__channel-preview-unread-badge {
        @apply size-4 overflow-hidden flex items-center justify-center bg-bn-primary-blue text-white text-[0.6875rem] leading-none font-medium p-0 pt-0.5;
      }
    }

    .str-chat__channel-preview-end-second-row {
      @apply gap-x-1;

      .str-chat__channel-preview-messenger--last-message,
      .str-chat__channel-preview-messenger--time {
        @apply text-xs md:text-sm;
      }
    }
  }
}
