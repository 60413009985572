$hover-color: #2880fe;
$primary-color: #2880fe;
$secondary-color: #f3cd3c;
$fab-button-color: #ecf4ff;
$error-color: #b00020;
$warn-color: #ffb81c;
$border-color: #f6f5f8;
$form-bg-color: #f6f5f8;
$input-color: #cbd1db;
$option-hoverbg-color: #f6f8ff;
$font-bright-color: darken(#b5b6c1, 25%);
$font-very-bright-color: #b5b6c1;
$tag-color: #f5dfe1;
$success-color: #d6ee9d;
$success-bright-color: lighten(#d6ee9d, 20%);
$success-dark-color: darken(#d6ee9d, 20%);
$box-bg-color: #f1f5fb;
$secondary-list-item: #f31225;
$secondary-container-color: #f5dfe1;
$bn-grey-font: #7d7e8b;
$bn-blue-light: #e1edff;
$bn-text-color: #212121;
