.str-chat {
  /** CUSTOM **/
  --str-chat__blue500: #2880FE;

  /** CUSTOM **/
  --str-chat__blue100: #D8E2FF;

  /** CUSTOM **/
  --str-chat__grey950: #45454f;

  /** CUSTOM **/
  --str-chat__grey500: #45454F;

  /** CUSTOM **/
  --str-chat__grey300: #F0F5FF;

  /** CUSTOM **/
  --str-chat__grey200: #F0F5FF;

  /** CUSTOM **/
  --str-chat__avatar: #E7EDFE;
  --str-chat__owntext: #001A41;
  --str-chat__primary-gray: #1C1B1F;
}
