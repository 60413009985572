@import '../colors';

$button: '.mdc-button';

.mat-mdc-outlined-button:not(:disabled) {
  border-color: $primary-color !important;
}

.mat-mdc-outlined-button.grey:not(:disabled) {
  border-color: #b6bdc9 !important;
}

#{$button} {
  @apply cursor-pointer;
  border-radius: 28px !important;
  height: 40px !important;

  &--light {
    #{$button}__label {
      @apply font-normal;
    }
  }

  &--grey:not(:disabled) {
    border-color: #b6bdc9 !important;
  }

  &__label {
    font-family: 'Orkney', 'Helvetica Neue', sans-serif !important;
    font-weight: bold;
    margin-top: 3px;
    letter-spacing: 1px !important;
    font-size: 14px !important;
  }
}
