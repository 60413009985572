.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
  padding: 16px !important;
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

// removes weird empty space
.mdc-dialog__title {
  display: flex !important;
  align-items: baseline !important;
}
