.str-chat__main-panel-inner {
  .str-chat__list {
    @apply scrollbar-thin;

    .str-chat__message-list-scroll {
      @apply px-4 py-0;

      .str-chat__ul {
        @apply flex flex-col flex-nowrap gap-y-5;

        .str-chat__li {
          &:first-of-type {
            @apply pt-0;
          }
        }
      }

      .str-chat__li {
        @apply ms-0 me-0 ps-0 pe-0;
      }
    }
  }
}

.str-chat__channel {
  .str-chat__empty-channel {
    @apply flex flex-1 items-center justify-center bg-bn-box ml-4 rounded-2xl px-6 xl:px-20 xl:mt-20 xl:bg-transparent;

    .str-chat__empty-channel-notifications,
    stream-icon {
      @apply hidden;
    }

    .str-chat__empty-channel-text {
      @apply mb-0 text-base text-bn-bright text-center;
    }
  }
}

.str-chat__jump-to-latest-message {
  inset-inline-end: 1.5rem;
  inset-block-end: 1.25rem;
}

.str-chat__jump-to-latest-message .str-chat__circle-fab {
  @apply shadow-none bg-bn-chat-avatar;

  .str-chat__icon {
    @apply text-bn-chat-secondary-grey;
  }
}
