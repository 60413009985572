.str-chat__message-textarea-angular-host {
  --str-chat__autocomplete-active-background-color: #e7ebf5;
  --str-chat__autocomplete-menu-background-color: var(--str-chat__grey300);
  --str-chat__autocomplete-menu-border-radius: var(
    --str-chat__border-radius-xxs
  );
  --str-chat__autocomplete-menu-box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --str-chat__spacing-2: 0.5rem;
  --str-chat__autocomplete-menu-color: var(--str-chat__primary-gray);
  --str-chat__mention-list-user-item-color: var(--str-chat__primary-gray);

  .mention-menu {
    border: none !important;

    .mention-item {
      @apply cursor-pointer;

      &:hover {
        background-color: var(--str-chat__autocomplete-active-background-color);
      }

      > a {
        @apply block transition-colors;
      }

      .str-chat__user-item {
        @apply p-2 gap-x-2;

        .str-chat__avatar {
          @apply size-8 min-w-8 text-bn-chat-primary-grey fill-bn-chat-primary-grey hidden;
        }

        .str-chat__user-item--name {
          @apply text-sm md:text-base text-bn-chat-primary-grey mt-1;
        }
      }
    }
  }
}
