.str-chat {
  --str-chat__channel-list-border-inline-end: none;
}

.str-chat__channel-list {
  .str-chat__channel-list-messenger {
    .str-chat__channel-list-messenger__main {
      .str-chat__channel-list-empty {
        @apply flex flex-1 items-center justify-center bg-bn-box mx-4 xl:mr-0 mb-4 xl:mb-0 rounded-2xl px-6 xl:px-20 text-base text-bn-bright;

        .str-chat__icon {
          @apply hidden;
        }
      }

      &:has(.str-chat__channel-list-empty) {
        .stream-chat__paginated-list,
        stream-paginated-list {
          @apply hidden;
        }
      }
    }
  }

  .stream-chat__paginated-list {
    @apply scrollbar-thin;
  }
}
