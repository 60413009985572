.str-chat__avatar {
  @apply relative flex items-center justify-center size-14 min-w-14 rounded-full;
  --str-chat__avatar-background-color: var(--str-chat__avatar);
  --str-chat__avatar-color: var(--str-chat__grey950);

  &.str-chat__avatar--one-letter,
  &.stream-chat__avatar--one-letter,
  &.str-chat__avatar--multiple-letters,
  &.stream-chat__avatar--multiple-letters {
    @apply font-bold text-base uppercase;
  }

  .str-chat__avatar-fallback {
    @apply flex items-center justify-center w-full h-full;
  }

  .str-chat__avatar-image {
    @apply w-full h-full object-cover;
  }
}

.str-chat__main-panel-inner {
  .str-chat__avatar {
    @apply size-10 min-w-10;
  }
}
