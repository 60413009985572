.str-chat__attachment-preview-list {
  @apply p-0 mb-2;
  --str-chat__attachment-preview-image-border-radius: var(
    --bn-chat__image-attachment-border-radius
  );

  .str-chat__attachment-list-scroll-container {
    @apply gap-x-2;
  }

  .str-chat__attachment-preview-delete {
    @apply top-1 text-white fill-white;
    inset-inline-end: 0.25rem;
  }
}
