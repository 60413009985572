.str-chat__main-panel-inner {
  .str-chat__quoted-message-preview {
    .str-chat__quoted-message-bubble {
      .str-chat__attachment-list {
        --str-chat__image-attachment-border-radius: var(
          --bn-chat__image-attachment-border-radius
        );

        .str-chat__message-attachment--gallery {
          .str-chat__gallery {
            .str-chat__gallery-placeholder {
              p {
                @apply mb-0;
              }
            }
          }
        }
      }

      .str-chat__message-attachment-unsupported,
      .str-chat__message-attachment-file--item {
        --str-chat__file-attachment-border-radius: 4px;
      }
    }
  }
}

.str-chat__attachment-list {
  .str-chat__message-attachment--gallery {
    .str-chat__gallery {
      .str-chat__gallery-placeholder {
        p {
          @apply mb-0 font-bold text-xl uppercase text-white;
        }
      }
    }
  }

  .str-chat__message-attachment-card {
    .str-chat__message-attachment-card--content {
      @apply pb-0;

      .str-chat__message-attachment-card--flex {
        @apply flex flex-col gap-y-1 text-sm;

        .str-chat__message-attachment-card--title {
          @apply font-bold;
        }
      }
    }
  }

  .str-chat__message-attachment-unsupported,
  .str-chat__message-attachment-file--item {
    @apply gap-x-2 p-2;
  }
}

.str-chat {
  .str-chat__main-panel-inner {
    .str-chat__message.str-chat__message--me {
      --str-chat__attachment-list-color: var(--str-chat__owntext);
      --str-chat__text-low-emphasis-color: var(--str-chat__owntext);
      --str-chat__border-radius-circle: none;

      .str-chat__audio_recorder__waveform-box,
      .str-chat__wave-progress-bar__track {
        .str-chat__wave-progress-bar__amplitude-bar--active {
          @apply bg-bn-chat-blue-text;
        }

        .str-chat__wave-progress-bar__progress-indicator {
          @apply bg-bn-chat-blue-text;
        }
      }
    }
  }
}

.str-chat__audio_recorder__waveform-box,
.str-chat__wave-progress-bar__track {
  @apply gap-x-0.5;

  .str-chat__wave-progress-bar__amplitude-bar {
    @apply w-px min-w-px;
  }

  .str-chat__wave-progress-bar__progress-indicator {
    @apply border-none w-[2px] bg-bn-chat-secondary-grey rounded;
  }
}

.str-chat__attachment-list .str-chat__message-attachment-unsupported,
.str-chat__attachment-list .str-chat__message-attachment-file--item {
}

.str-chat__attachment-list .str-chat__message-attachment-file--item,
.str-chat__attachment-list .str-chat__message-attachment-audio-widget {
}
