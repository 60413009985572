.str-chat {
  /** CUSTOM **/
  --str-chat__border-radius-xxs: 4px;

  /** CUSTOM **/
  /* Border radius used for rounded elements */
  --str-chat__border-radius-md: 16px;

  /** CUSTOM **/
  --str-chat__border-radius-xl: 28px;

  /** CUSTOM **/
  /* The font used in the chat, by default, we use [preinstalled OS fonts](https://systemfontstack.com/) */
  --str-chat__font-family: 'Orkney', 'Helvetica Neue', sans-serif;

  /** CUSTOM **/
  /* The font used for body texts */
  --str-chat__body-text: 0.75rem/1 var(--str-chat__font-family);

  /** CUSTOM **/
  /* The font used for body texts */
  --str-chat__body2-text: 0.875rem/1.25 var(--str-chat__font-family);

  /** CUSTOM **/
  /* The font used for body texts with emphasize */
  --str-chat__body2-medium-text: 500 0.875rem/1.25 var(--str-chat__font-family);

  /** CUSTOM **/
  /* The font used for subtitle texts with emphasize */
  --str-chat__subtitle-medium-text: 700 0.875rem/1.25
    var(--str-chat__font-family);

  /** CUSTOM **/
  --bn-chat__message-bubble-border-radius: var(--str-chat__border-radius-xs);
  --bn-chat__image-attachment-border-radius: var(--str-chat__border-radius-xxs);
  --bn-chat__message-input-border-radius: var(--str-chat__border-radius-lg);

  /** CUSTOM **/
  @media (min-width: 768px) {
    --str-chat__subtitle-medium-text: 700 1rem/1.5 var(--str-chat__font-family);
    --str-chat__body2-text: 1/1.5 var(--str-chat__font-family);
    --str-chat__body2-medium-text: 500 1rem/1.5 var(--str-chat__font-family);
    --bn-chat__message-bubble-border-radius: var(--str-chat__border-radius-md);
    --bn-chat__image-attachment-border-radius: var(--str-chat__border-radius-xs);
    --bn-chat__message-input-border-radius: var(--str-chat__border-radius-xl);
  }
}

.str-chat,
.str-chat__theme-light {
  /** CUSTOM **/
  /* The main color used for texts/icons */
  --str-chat__text-color: var(--str-chat__grey950);
}
