.str-chat__message-input {
  .str-chat__message-textarea-container {
    @apply min-h-[2.375rem];

    .str-chat__message-textarea {
      @apply placeholder:text-bn-chat-secondary-grey scrollbar-thin;
      font: var(--str-chat__body2-text);
      font-size: 16px; // prevent zoom in on mobile browsers
      line-height: 1.5rem;
    }
  }
}

.str-chat__message-input-angular-host {
  .recorder {
    .bar {
      .voice-recording {
        @apply py-0 pl-0 min-h-8;
      }
    }
  }
}
